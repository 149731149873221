.menuOption {
  display: flex;
  gap: 8px;
  align-items: center;
}

.menuItem {
  background: #fff !important;
}

.dispatched {
  color: #00b779 !important;
}

.notDispatched {
  color: #ffab00 !important;
  justify-content: center;
}

.inputBaseRootClassName {
  border: 1.5px solid #cfcdc9 !important;
}

.selectIcon {
  color: #272522 !important;
  width: 7px;
  height: 3px;
  margin-right: 6px;
}
