.titleContainer {
  display: flex;
  align-items: center;
  margin-bottom: 4px;
}

.label {
  color: #8f85c7;
  font-size: 12px;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.48px;
}

.titleContainer svg {
  color: #dcdaec !important;
  margin-left: -7.925px;
}

.contentContainer {
  border-left: 1px solid #ddd9f3;
  padding: 8px 0px 8px 12px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 12px;
}
