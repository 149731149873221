.emptyListTitle {
  color: #1c274c;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.emptyListSubtitle {
  color: #959595;
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.emptyListWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  width: 100%;
}
