.header {
  height: 59.5px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 24px;
  /* earlier when js file was there -> theme?.palette?.secondary?.shade_100 */
  border-bottom: 1.5px solid #e7e6e4;
}

.name_wrapper {
  display: flex;
  align-items: center;
  gap: 8px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  line-height: 17px;
  font-weight: 500;
}

.header_icons {
  display: flex;
  gap: 25.43px;
}

.cross_icon_wrapper:hover {
  cursor: pointer;
}
