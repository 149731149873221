.dateDivWrapper {
  display: flex;
  align-items: center;
  gap: 20px;
}

.horizontalDiv {
  height: 1px;
  opacity: 0.5;
  background: #b9b2e6;
  width: 100%;
}

.dateDiv {
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  display: flex;
  color: rgba(39, 37, 34, 0.8);
  display: flex;
  padding: 4px 24px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 12px;
  border: 0.5px solid #e4e4e4;
  background: #fff;
  white-space: nowrap;
}
