.container {
  height: fit-content;
  max-height: 78vh;
  overflow-y: auto;
  padding: 0 16px;
  display: flex;
  flex-direction: column;
  /**/
  padding-bottom: 12px;
  margin-bottom: 12px;
}

.card {
  border-radius: 5px;
  border: 1.5px solid #dcd9f2;
  margin-top: 24px;
}

.header {
  background-color: #dcd9f2;
  padding: 16px;
  border-radius: 5px 5px 0 0;
}

.headerText {
  color: #493ab1;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
}

.message {
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  margin-bottom: 16px;
}

.responseText {
  background: #ffffff;
  border: 1.5px solid #cfcdc9;
  border-radius: 5px;
  padding: 10px 12px;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: rgba(0, 0, 0, 0.6);
}

.questionText {
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
}

.content {
  padding: 24px 16px;
  gap: 24px;
  display: flex;
  flex-direction: column;
}

.iconWrapper {
  display: flex;
  align-items: center;
  gap: 4px;
}

.iconText {
  font-size: 14px;
  color: rgba(39, 37, 34, 0.7);
}

.iconWrapperBox {
  display: flex;
  gap: 16px;
  margin-top: 8px;
}

.mobileIconWrapperBox {
  flex-direction: column;
  gap: 8px;
}

.titleIcon {
  width: 16px !important;
  height: 16px !important;
  color: rgba(39, 37, 34, 0.7);
}

.questionWrapper {
  display: flex;
  gap: 8px;
  flex-direction: column;
}

.lastElementStyle {
  visibility: hidden;
}

.wrapperClassname {
  padding-top: 0 !important;
  width: 100% !important;
}
