.root {
  display: flex;
  justify-content: space-between;
  gap: 43px;
}

.label {
  white-space: nowrap;
  color: #000;
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
  padding-top: 9px;
}

.inputParentWrapper {
  background: white !important;
}

.actions {
  display: flex;
  gap: 4px;
}

.disabled {
  opacity: 0.7;
  cursor: not-allowed !important;
}

.currencySymbol {
  margin-left: 12.32px;
  margin-right: -6px !important;
  color: #a4a19b;
}

.currencySymbol:hover {
  background-color: white !important;
  background: white !important;
}
