.complexDropdown {
  min-width: 198px !important;
}

.complexDropdownWidth {
  max-width: 198px !important;
}

@media only screen and (max-width: 768px) {
  .complexDropdown {
    min-width: 155px !important;
  }
  .complexDropdownWidth {
    max-width: 155px !important;
  }
}
