.timepicker {
  width: calc(100% - 40px) !important;
  height: 32px !important;
}

.timeInput {
  height: 29px !important;
  font-size: 14px !important;
}

.disabledTime {
  color: rgba(0, 0, 0, 0.38) !important;
}
