.chipStyle {
  border-radius: 4px;
  border: 1px solid #493ab1;
  background: #eeecf9;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  color: #493ab1;
  padding: 0px 8px;
  width: fit-content;
}

.variantWrapper {
  display: flex;
  gap: 8px;
}
