.urlInputClass {
  height: 32px !important;
  background-color: white !important;
}

.urlWrapper {
  position: relative;
}

.error {
  font-size: 12px !important;
  position: absolute;
  bottom: 16;
  display: flex;
  align-items: center;
}

.errorIcon {
  font-size: 14px !important;
}

@media only screen and (max-width: 768px) {
  .urlInputClass {
    min-width: 155px !important;
  }

  .error {
    font-size: 10px !important;
    bottom: -16px;
  }

  .errorIcon {
    font-size: 12px !important;
  }
}
