.dropdownFormControl {
  min-width: 155px !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.dropdownFormControlWidth {
  max-width: 155px !important;
}

.dropdownPoppver {
  max-height: 300px !important;
}
