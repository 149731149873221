.modalPaper {
  width: 440px;
}

.inputClass {
  height: 102px !important;
  text-align: flex-start !important;
  width: 400px;
}
.inputElement {
  height: 70px !important;
  overflow-y: auto !important;
  font-size: 12px !important;
  width: 400px;
}

.modalContent {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px;
}

@media only screen and (max-width: 768px) {
  .inputClass {
    max-width: calc(100vw - 32px);
  }
  .inputElement {
    max-width: calc(100vw - 32px);
  }
}
