.inputClass {
  height: 48px !important;
  background-color: white !important;
}

.inputElement {
  height: 38px !important;
  overflow-y: auto !important;
  text-align: flex-start !important;
  font-size: 12px !important;
  padding: 4px 8px !important;
}

@media only screen and (max-width: 768px) {
  .inputClass {
    height: 48px !important;
    min-width: 155px !important;
    margin: 0px 0px !important;
  }
}
