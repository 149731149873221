.iconWrapper {
  width: 26px;
  height: 24px;
  background-color: #e0e0ff !important;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  margin-right: 4px;
  cursor: pointer;
}

.disabledIconWrapper {
  background-color: #edecec !important;
  pointer-events: none;
  cursor: not-allowed;
}

.icon {
  width: 20px !important;
  height: 20px !important;
  color: #493ab1 !important;
}

.disabledIcon {
  color: #a5a5a5 !important;
}
