.footerWrapper {
  display: flex;
  width: 100%;
  padding: 8px 12px;
  align-items: flex-end;
  gap: 16px;
  background: #b9b2e6;
  height: fit-content;
}

.modalPaperClass {
  min-width: 428px !important;
}

.titleText {
  color: rgba(0, 0, 0, 0.85);
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.titleWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

.inputStyle {
  display: flex !important;
  min-height: 46px !important;
  height: fit-content !important;
  border-radius: 12px !important;
  background: #fff;
  width: 100% !important;
  padding: 8px !important;
  align-items: flex-start !important;
}

.inputWrapper {
  width: 100% !important;
}

.addNoteBtn {
  display: flex;
  height: 46px;
  padding: 12px;
  justify-content: center;
  align-items: center;
  gap: 6px;
  border-radius: 12px;
  background: #493ab1;
  cursor: pointer;
  margin: 0px;
  border: none;

  &:disabled {
    cursor: not-allowed;
    opacity: 0.7;
  }
}

.adornmentIconStyle {
  width: 18px !important;
  height: 18px !important;
}

.inputElementClassName {
  padding: 0 !important;
  max-height: 100px !important;
  overflow-y: auto !important;
}
