.other {
  animation: animateRoot 0.5s ease forwards;
}

@keyframes animateRoot {
  from {
    transform: translateY(10%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}
