.noteCardContainer {
  border-radius: 12px;
  border-top: 10px solid #f1dd65;
  background: #f9eca9;
  display: flex;
  flex-direction: column;
  width: fit-content;
  padding: 8px 12px 12px 12px;
  align-items: flex-start;
  gap: 16px;
  word-break: break-all;
  align-self: flex-end;
  max-width: 95%;
}

.noteBottomContainer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.dotStyle {
  width: 6px !important;
  height: 6px !important;
  color: #959595;
}

.noteBottomSpanText {
  color: #959595;
  font-feature-settings: "liga" off, "clig" off;
  font-size: 12px;
}

.italicText {
  font-style: italic;
}

.noteBottomContainer .noteBottomLeft {
  display: flex;
  gap: 4px;
  align-items: center;
}

.noteBottomContainer .noteBottomLeft .noteStackIcon {
  height: 14px;
  width: 14px;
  color: #959595;
}

.noteBottomContainer .noteBottomRight {
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 0px 4px;
}

.noteBottomContainer .noteBottomRight .noteBy {
  display: flex;
  gap: 2px;
  align-items: center;
}

.personIcon {
  height: 14px;
  width: 14px;
}

.readMoreText {
  color: #0052cc;
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  text-decoration-line: underline;
  cursor: pointer;
}

.messageStyle {
  overflow: hidden;
  color: #1c274c;
  display: -webkit-box;
  white-space: nowrap;
  font-size: 14px;
  line-height: 22px;
  white-space: pre-wrap !important;
  overflow-wrap: break-word;
}

.deletedWrapper {
  display: flex;
  align-items: center;
  gap: 7px;
}

.deletedWrapper .deletedIcon {
  color: #959595;
  height: 18px;
  width: 18px;
}

.deletedWrapper .deletedText {
  color: #959595;
  font-size: 14px;
  font-style: italic;
  font-weight: 400;
  line-height: 24px;
}
