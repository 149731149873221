.listContainerWrapper {
  display: flex;
  width: 472px;
  height: 296px;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  background: #eeecf9;
  flex-direction: column;
  justify-content: center;
}

.loader {
  height: auto;
  margin: 16px 0px;
}

.notesWrapper {
  display: flex;
  flex-direction: column-reverse;
  gap: 16px;
  padding: 18px 12px;
  height: 100%;
  overflow-y: auto;
  width: 100%;
  padding-bottom: 0px;
}

.lastElementStyle {
  visibility: hidden;
  height: 20px;
}

.scrollToDown {
  background: #493ab1;
  box-shadow: 0px 3px 6px rgba(14, 13, 12, 0.32);
  border-radius: 50%;
  z-index: 10;
  height: 36px;
  width: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 2px;
}

.scrollToDown:hover {
  cursor: pointer;
}

.scrollToDownWrapper {
  display: flex;
  justify-content: end;
  position: absolute;
  bottom: 70px;
  right: 18px;
}

@media (max-width: 768px) {
  .listContainerWrapper {
    width: 100%;
    height: 432px;
  }
}
