.inter_text_style {
  color: #272522;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
  max-width: 931px;
}

.inner_numeric_style {
  font-weight: 500;
}
