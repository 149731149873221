.imageContainer {
  position: relative;
}

.imageContainer .downloadIcon {
  position: absolute;
  right: 10px;
  top: 12px;
  height: 24px;
  width: 24px;
  background-color: #fff;
  border-radius: 50%;
  padding: 2px;
  cursor: pointer;
}

.imageContainer .fullscreenIcon {
  position: absolute;
  z-index: 12;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
  cursor: pointer;
  border-radius: 6px;
}

.imageContainer:hover .fullscreenIcon {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset,
    rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
}

.desktopModalPaper {
  width: fit-content;
  max-width: fit-content !important;
  height: fit-content;
}

.mobileModalPaper {
  width: 100vw;
  height: 100vh;
  border-radius: 0px;
}

.invisibleHeader {
  position: absolute;
  top: 12px;
  right: 12px;
  z-index: 100;
}

.invisibleHeader .closeIcon {
  color: #fff;
  cursor: pointer;
}

.fullscreenImage {
  max-height: 90vh;
  max-width: 90vw;
  height: 100%;
  width: 100%;
  object-fit: contain;
}

.image {
  flex-shrink: 0;
  border-radius: 6px;
  max-height: 200px;
  min-width: 150px;
  width: 100%;
  height: 100%;
  align-self: center;
}

.video {
  width: 100%;
  min-width: 300px;
  height: 200px;
  border-radius: 6px;
  overflow: hidden;
}

.formResponseImage {
  max-width: 100%;
  width: fit-content;
  align-self: flex-start;
}

.whatsappMsgImage {
  width: 260px;
}

.formResponseVideo {
  align-self: flex-start;
  max-width: fit-content;
}

.emptyMediaContainer {
  display: flex;
  align-items: center;
  gap: 8px;
  opacity: 0.5;
}

.emptyMediaContainer .emptyMediaText {
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
}

@media screen and (max-width: 768px) {
  .video {
    min-width: 200px;
  }

  .fullscreenImage {
    max-height: 100dvh;
    max-width: 100vw;
    height: 100%;
    width: 100%;
    object-fit: contain;
  }

  .mobileModalRoot {
    height: 100dvh;
    width: 100vw;
  }

  .invisibleHeader .closeIcon {
    color: rgba(0, 0, 0, 0.85);
  }

  .whatsappMsgImage {
    width: 200px;
  }
}
