.readMoreText {
  color: #493ab1;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.28px;
  text-decoration-line: underline;
  cursor: pointer;
  margin-bottom: 8px;
}

.textWrapper {
  padding-top: 8px;
}
