.datePicker {
  min-width: 160px !important;
  background-color: white !important;
}

.datePickerDisabled input {
  color: rgba(0, 0, 0, 0.38) !important;
}

.datePickerDisabled {
  pointer-events: none !important;
}

.datePicker:hover {
  background-color: white !important;
}

.datePickerPopperClassName {
  z-index: 10 !important;
}

@media only screen and (max-width: 768px) {
  .datePicker {
    min-width: 155px !important;
  }
}
