.batchFieldContainer {
  display: flex;
  align-items: center;
  gap: 4px;
}

.batchInfoIcon {
  width: 16px !important;
  height: 16px !important;
  color: #d9d9d9;
}
