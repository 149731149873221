.root {
  border: 1px solid #ddd9f3;
  box-shadow: 0px 2px 4px rgba(174, 167, 214, 0.3);
  border-radius: 12px;
  overflow: hidden;
}

.header {
  border-bottom: 1px solid #ddd9f3;
  background-color: #f4f3fa;
  color: #272522;
  font-size: 16px;
  font-weight: 500;
  line-height: normal;
  padding: 16px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  user-select: none;
}

.body {
  padding: 18px 16px 32px 16px;
  display: flex;
  flex-direction: column;
  gap: 18px;
}

.chevronDown {
  transition: all 0.3s ease;
}

.chevronDownActive {
  transform: rotate(180deg);
}
