.root {
  display: flex;
  align-items: center;
}

.rule {
  height: 1px;
  background: #e7e6e4;
  flex: 1;
}

.label {
  color: rgba(39, 37, 34, 0.6);
  font-size: 12px;
  font-weight: 500;
  line-height: normal;
  padding: 1.3px 11.21px;
  border-radius: 14.5px;
  background: #fafafa;
}
