.root {
  height: auto !important;
  overflow: auto !important;
}

@media screen and (max-width: 768px) {
  .root {
    overflow-y: auto;
    height: calc(100vh - 95.5px) !important;
  }
}
