.modalContainer {
  border-radius: 12px !important;
  width: 600px !important;
  max-width: 600px !important;
}

.modalContainerHeight {
  height: 200px;
}

.footer {
  height: 59.5px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 18px 16px;
  border-top: 1.5px solid #e7e6e4;
}

.cardContainer {
  display: flex;
  flex-direction: column;
  padding: 20px;
  justify-content: center;
  width: 50%;
}
.headingText {
  font-weight: bold;
  color: #493ab1;
}
.tooltip {
  font-size: 18px !important;
  margin-top: -3px;
  opacity: 0.6;
  cursor: pointer;
}
.gapContainer {
  gap: 10px;
}
.noteInfoContainer {
  padding: 20px 0px;
}
.loader {
  height: 100%;
}
.currencySymbol {
  margin-left: 12.32px;
}
.refundAmountInput {
  padding-left: 0px !important;
}

@media only screen and (min-width: 768px) {
  .footer {
    padding: 0 24px;
  }
  .noteInfoContainer {
    padding: 0;
  }
}
