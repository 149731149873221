.readMoreText {
  color: #493ab1;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.28px;
  text-decoration-line: underline;
  cursor: pointer;
  margin-bottom: 8px;
}

.wrapper {
  width: 300px;
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: flex-start;
  gap: 8px;
  width: 100%;
}

.iconWrapper {
  background-color: #e6e6e6;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 4px;
}

.icon {
  width: 16px !important;
  height: 16px !important;
}

.disabledIcon {
  cursor: not-allowed;
}

.text {
  color: rgba(0, 0, 0, 0.85);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 15px;
  letter-spacing: 0.24px;
  word-break: break-all;
  width: calc(100% - 72px);
  margin-top: 0 !important;
  white-space: pre-wrap;
}

.seeMore {
  color: #493ab1;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 15px;
  letter-spacing: 0.24px;
  cursor: pointer;
}

.inputDisabled {
  color: rgba(0, 0, 0, 0.38) !important;
}
@media only screen and (max-width: 768px) {
  .wrapper {
    width: 200px;
    justify-content: flex-end !important;
  }
}
